import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';

import Link from '../net/Link';
/*
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import  * as actions  from '../actions/actions';
*/
import {Motion, spring } from 'react-motion'; 

import CloseMenuIcon from './CloseMenuIcon';

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.fadeInFwends = this.fadeInFwends.bind(this);
    this.state = {width: 8000}; //move the sidebar out of the view immediately
  }

  fadeInFwends() {
    if (this.props.menu_open) {
    
    let o = this.state.fwendsOpacity + 0.2;
    this.setState({fwendsOpacity: o});
    if (o < 1) {
      setTimeout(() => {this.fadeInFwends()}, 50);
    }
    } else {
      this.setState({fwendsOpacity: 0});
    }
     
  }

  updateWindowDimensions() {
     this.setState({width: window.innerWidth});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({width: window.innerWidth, fwendsOpacity: 0});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  toggleMenu(event) {
    event.preventDefault();
    //this.props.actions.close_menu();
    this.props.onMenuToggle(! this.props.menu_open);
  }

  render () {
    /*
      The structure is as thus:
      We have a sidebar, which always has the width of the window, and sets at left=0 when opened.
      Inside this sidebar is a wrapper, that, depending on window width, has two states:
        1. For mobile devices (600px and below), it also sets at left=0
        2. For desktop devices (above), it sets at window-width / 2, and therefore has a width of half the window

        This is interesting because the colored background is set on the wrapper, not the sidebar itself.

    */


    /*
    Standard styles for resolutions below 600
    */

    let wrapperStyle = {
      left: 0,
      width: this.state.width
    }
    //TODO: Hack. Structure is off. Just check window width and set accordingly
    //BEFORE checking if open or not.
    if (this.state.width > 600) {
      wrapperStyle.left = this.state.width / 2; //half
      wrapperStyle.width = this.state.width / 2;
    }

    //default style of the sidebar div if we are not opened
    let defaultStyle = {
      left: this.state.width,
    }
    
    //if we are not open, this
    //moves the sidebar out of the viewport without animation
    let style={
      left: this.state.width
    }
    
    /*
    Styles for open sidebar
    */

    if (this.props.menu_open) {
      let left = 0;
       if (this.state.width > 600) {
          // Set the wrapper to its correct state

          left = this.state.width / 2; //half

          wrapperStyle = {
            left: left,
            width: left
          }

        }

      //the sidebar itself will always move back to 0
      style = {
        left: spring(0)
      }

    }

    let interceptStyle = {
      left: 0,
      width: wrapperStyle.left
    }
  
    let fadeStyle = {
      opacity: this.state.fwendsOpacity
    }

    return (
      <Motion defaultStyle={defaultStyle} style={style} onRest={() => {this.fadeInFwends()}}>
      
      {interpolatingStyle => <div id="sidebar" style={interpolatingStyle}>
        
      <Navbar className="top">
        <Container fluid className="w-80">
        <NavbarBrand href="/"><img src="/Fwends-Logo_inverted.png" className="logo" style={fadeStyle} alt="Fwends Logo"/></NavbarBrand>
        <NavbarToggler onClick={this.toggleMenu}><CloseMenuIcon /></NavbarToggler>
        </Container>
        </Navbar>
        <Container fluid className="w-80">
          <Row>
            <Col md={{offset:6, width:6}} className="sidebar-menu">
              <Link id="about" className="menu-item" to="/about">About</Link>
              {/*
              <Link id="services" className="menu-item" to="/services/">Services</Link>
              <Link id="projects" className="menu-item" to="/projects/">Projects</Link>
              */
              <Link id="about" className="menu-item" to="/articles/towards_a_human_way_of_working/">A Manifesto</Link>
            }
            <Link id="articles" className="menu-item" to="/articles/">Articles</Link>
              <Link  id="letsbefwends" className="menu-item" to="/lets-be-fwends/">Let's be fwends</Link>
              
              <Link to="https://www.linkedin.com/in/peter-riegersperger-8413b623/" className="icon-link"><img src="/icons/linkedin-with-circle.svg" alt="Link to LinkedIn Profile" /></Link>
              <Link to="https://twitter.com/lifeintheslow"  className="icon-link"><img src="/icons/twitter-with-circle.svg" alt="Link to Twitter Account"/></Link>
              <Link rel="me" to="https://mastodon.social/@lifeintheslow" className="icon-link"><img src="/icons/mastodon-with-circle.svg" alt="Link to Mastodon Account"/></Link>
              
          </Col>
          </Row>
        </Container>
      {// eslint-disable-next-line
      }<div id="click-intercept"  style={interceptStyle} onClick={this.toggleMenu}/>

      <div id="sidebar-wrapper" style={wrapperStyle}>
        <Link id="imprint" className="menu-item-imprint" to="/imprint/">Imprint</Link>
      </div>
      </div>}
      </Motion>
    )
  }
}
/*
function mapStateToProps(state, ownProps) {
  return {
    menu_open: state.menu.menu_open
  }
}

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
*/
export default Sidebar;